import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf, t } from '../../../domain/services/configService';

const onClickHandler = () => {
  window.Optanon.ToggleInfoDisplay();
};

export const OptanonButton = ({ color, isLink }) => {
  const style = {
    button: css`
      background: unset;
      color: ${color};
      ${isLink
        ? 'border: unset;border-bottom: 2px solid #fc0;'
        : 'border: unset;'}
    `
  };
  const optanonLink = conf.menus.menuFooter.find(
    link => link.class === 'ot-sdk-show-settings'
  );

  return (
    <button
      css={style.button}
      onClick={onClickHandler}
      onKeyPress={onClickHandler}
      type="button"
      tabIndex={0}
    >
      {optanonLink ? optanonLink.title : t('Cookie Settings')}
    </button>
  );
};

OptanonButton.defaultProps = {
  color: '#fff',
  isLink: false
};

OptanonButton.propTypes = {
  color: PropTypes.string,
  isLink: PropTypes.bool
};
