import React from 'react';
import { css } from '@emotion/react';
import { theme } from '../../../../../domain/theme';
import { conf, t } from '../../../../../domain/services/configService';
import { SmartLink } from '../../../../SmartLink';
import { DrupalEdit } from '../../../molecules/DrupalEdit';

const styles = {
  container: css`
    margin-top: ${theme.spacing('l')};
    ${theme.mq.tablet} {
      margin: 0;
    }
  `,
  title: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('xs')};
    line-height: initial;
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
    color: #a5a5a5;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('base')};
    }
  `,
  nav: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('s')};
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('l')};
    }
    li {
      margin-top: ${theme.spacing('s')};
      line-height: initial;
      a {
        color: #fff;
        &:hover {
          color: #fff;
        }
        ${theme.mq.tablet} {
          color: #a5a5a5;
        }
      }
      ${theme.mq.tablet} {
        margin-top: ${theme.spacing('base')};
      }
    }
  `
};

export const OtherSectionsMenu = () => {
  return conf.menus.menuSections.length > 0 ? (
    <div css={styles.container}>
      <h3 css={styles.title}>
        {t('Other sections')}
        <DrupalEdit entityType="menu" entityId="sections" color="white" />
      </h3>
      <nav title={t('Other sections')} css={styles.nav}>
        <ul>
          {conf.menus.menuSections.map(link => (
            <li key={link.url.path} className={link.containerClass}>
              <SmartLink
                to={link.url.path}
                title={link.title}
                target={link.target}
                className={link.class}
                dataLayerClickEvent={
                  link.dataLayerEvent
                    ? {
                        event: link.dataLayerEvent,
                        location: 'HamburgerMenu'
                      }
                    : null
                }
              >
                {link.title}
              </SmartLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  ) : null;
};
