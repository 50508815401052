import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../../../domain/theme';
import { conf, t } from '../../../../../domain/services/configService';
import { imageResizerService } from '../../../../../domain/services/imageResizerService';
import { breakpointService } from '../../../../../domain/services/breakpointService';
import { SmartLink } from '../../../../SmartLink';
import { DrupalEdit } from '../../../molecules/DrupalEdit';

const styles = {
  title: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('s')};
    line-height: initial;
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
    color: #a5a5a5;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('base')};
    }
  `,
  nav: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('l')};
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('xl2')};
    }
    li {
      margin-top: ${theme.spacing('s')};
      line-height: initial;
      a {
        color: #fff;
        &:hover {
          color: #fff;
        }
        ${theme.mq.tablet} {
          color: #a5a5a5;
        }
      }
      ${theme.mq.tablet} {
        margin-top: ${theme.spacing('l')};
        &:first-of-type {
          margin-top: ${theme.spacing('base')};
        }
      }
    }
  `,
  mainNavigation: css`
    display: block;
    ${theme.mq.tablet} {
      display: none;
    }
  `
};

const getImageUrl = link => {
  if (link.image?.uri?.value) {
    const imageWidth = breakpointService.getCurrentBreakpoint().width;
    return imageResizerService.getStyleUrl(
      link.image.uri.value.replace('public://', '/files/'),
      imageWidth,
      Math.ceil(imageWidth * 0.6666) // 2X3 aspect
    );
  }
  return null;
};

const getAllImagesUrl = () =>
  conf.menus.menuContent.map(link => getImageUrl(link));

export const ContentMenu = ({ bgImageRef }) => {
  useEffect(() => {
    if (!breakpointService.isMobile()) {
      getAllImagesUrl().forEach(imageUrl => {
        if (imageUrl) {
          const image = new Image();
          image.src = imageUrl;
        }
      });
    }
  }, []);

  let hideImageTimeoutId;
  const showImage = link => {
    if (!breakpointService.isMobile()) {
      clearTimeout(hideImageTimeoutId);
      const imageUrl = getImageUrl(link);
      if (imageUrl) {
        const currentBgImageRef = bgImageRef.current;
        currentBgImageRef.style.backgroundImage = `url(${imageUrl})`;
      }
    }
  };

  const hideImage = () => {
    if (!breakpointService.isMobile()) {
      // Wait a little to give enough time to move mouse to another link without
      // hiding the previous image
      hideImageTimeoutId = setTimeout(() => {
        const currentBgImageRef = bgImageRef.current;
        if (currentBgImageRef && currentBgImageRef.style) {
          currentBgImageRef.style.backgroundImage =
            'url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==)';
        }
      }, 450);
    }
  };

  const linkTag = (link, { bgImage = false } = {}) => {
    const to =
      link.url?.path || link.url?.entity?.url?.path || link.url.externalUrl;
    return (
      <li
        key={`ContentMenu-${to}-${link.title}`}
        className={link.containerClass}
      >
        <SmartLink
          to={to}
          title={link.title}
          target={link.target}
          className={link.class}
          dataLayerClickEvent={
            link.dataLayerEvent
              ? {
                  event: link.dataLayerEvent,
                  location: 'HamburgerMenu'
                }
              : null
          }
          onFocus={() => bgImage && showImage(link)}
          onMouseOver={() => bgImage && showImage(link)}
          onBlur={() => bgImage && hideImage()}
          onMouseOut={() => bgImage && hideImage()}
        >
          {link.title}
        </SmartLink>
      </li>
    );
  };

  return conf.menus.menuContent.length > 0 ? (
    <div>
      <h3 css={styles.title}>
        {t('__MENU_TOP_EXPLORE__')}
        <DrupalEdit entityType="menu" entityId="content" color="white" />
      </h3>
      <nav title={t('__MENU_TOP_EXPLORE__')} css={styles.nav}>
        <ul css={styles.mainNavigation}>
          {conf.menus.menuMainNavigation.map(link => linkTag(link))}
        </ul>
        <ul>
          {conf.menus.menuContent.map(link => linkTag(link, { bgImage: true }))}
        </ul>
      </nav>
    </div>
  ) : null;
};

ContentMenu.propTypes = {
  bgImageRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired
};
