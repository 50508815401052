import React from 'react';
import { css } from '@emotion/react';
import { theme } from '../../../../../domain/theme';
import { conf, t } from '../../../../../domain/services/configService';
import { SmartLink } from '../../../../SmartLink';
import { DrupalEdit } from '../../../molecules/DrupalEdit';

const styles = {
  container: css`
    margin-top: ${theme.spacing('l')};
    ${theme.mq.tablet} {
      margin-top: ${theme.spacing('xl3')};
    }
  `,
  title: css`
    display: block;
    font-size: ${theme.font.size('xs')};
    ${theme.font.family('boldWeb')};
    line-height: initial;
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
    color: #a5a5a5;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('s')};
    }
  `,
  mobileTitle: css`
    display: inline;
    ${theme.mq.tablet} {
      display: none;
    }
  `,
  desktopTitle: css`
    display: none;
    ${theme.mq.tablet} {
      display: inline;
    }
  `,
  nav: css`
    display: inline-block;
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('xs')};
    letter-spacing: ${theme.letterSpacing('base')};
    text-transform: uppercase;
    ${theme.mq.tablet} {
      font-size: ${theme.font.size('xl2')};
    }
    li {
      /*       margin: ${theme.spacing.parse('$xs 0')};
      padding: ${theme.spacing('xs')};
      border: 1px solid #fff; */
      margin-top: ${theme.spacing('s')};
      a {
        color: #fff;
        &:hover {
          color: #fff;
        }
        ${theme.mq.tablet} {
          color: #a5a5a5;
        }
      }
      ${theme.mq.tablet} {
        margin: ${theme.spacing.parse('$xl 0')};
        padding: 0;
        border: none;
        &:first-of-type {
          margin-top: ${theme.spacing('base')};
        }
      }
    }
  `
};

export const SubscribeMenu = () => {
  return conf.menus.menuSubscribeHeader.length > 0 ? (
    <div css={styles.container}>
      <h3 css={styles.title}>
        {t('Subscribe to')}
        <DrupalEdit
          entityType="menu"
          entityId="subscribe-header"
          color="white"
        />
      </h3>
      <nav title={t('Subscribe to')} css={styles.nav}>
        <ul>
          {conf.menus.menuSubscribeHeader.map(link => (
            <li key={link.url.path} className={link.containerClass}>
              <SmartLink
                to={link.url.path}
                title={link.title}
                target={link.target}
                className={link.class}
                dataLayerClickEvent={
                  link.dataLayerEvent
                    ? {
                        event: link.dataLayerEvent,
                        location: 'HamburgerMenu'
                      }
                    : null
                }
              >
                <span css={styles.mobileTitle}>{link.title}</span>
                <span css={styles.desktopTitle}>
                  {link.desktopTitle || link.title}
                </span>
              </SmartLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  ) : null;
};
