import React from 'react';
import { css } from '@emotion/react';
import { theme } from '../../../../../domain/theme';
import { DrupalEdit } from '../../../molecules/DrupalEdit';
import { FooterMenuItems } from '../../FooterMenuItems';

/**
 * This component is named after "legal", but in fact it shows menu items from "footer" menu.
 *
 * Its name is Legal*Hamburger*Menu to distinguish it from another LegalMenu used in the footer
 */
export const LegalHamburgerMenu = () => {
  const styles = {
    container: css`
      margin-top: ${theme.spacing('l')};
      ${theme.mq.tablet} {
        margin-top: ${theme.spacing('xl3')};
      }
    `,
    nav: css`
      ${theme.font.family('boldWeb')};
      font-size: ${theme.font.size('xs')};
      letter-spacing: ${theme.letterSpacing('base')};
      text-transform: uppercase !important;
      ${theme.mq.tablet} {
        ${theme.font.family('boldWeb')};
        font-size: ${theme.font.size('s')};
      }
      button {
        text-align: left;
        letter-spacing: ${theme.letterSpacing('base')};
        text-transform: uppercase !important;
      }
    `,
    navLi: css`
      margin-top: ${theme.spacing('s')};
      line-height: initial;
      a,
      button {
        color: #fff;
        &:hover {
          color: #fff;
        }
        ${theme.mq.tablet} {
          color: #a5a5a5;
        }
      }
      ${theme.mq.tablet} {
        margin-top: ${theme.spacing('base')};
      }
    `,
    drupalEditIcon: css`
      margin-left: -20px;
    `
  };

  return (
    <div css={styles.container}>
      <nav css={styles.nav}>
        <div css={styles.drupalEditIcon}>
          <DrupalEdit entityType="menu" entityId="footer" color="white" />
        </div>
        <FooterMenuItems styleMenuItem={styles.navLi} />
      </nav>
    </div>
  );
};
